<template>
    <SmartTable url="/settings/tipi-costituzione/index" base_path="/settings/tipi-costituzione"></SmartTable>
</template>
<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipi Costituzioni", route: "/settings/tipi-costituzione/index" },
             { title: "Ricerca Tipi Costituzioni" }
        ]);
    },
 };
</script>
